import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    PageHeaderC,
    PageSection,
} from '../../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../../components/typography';
import { BoxLight } from '../../components/box';
import { StaticImage } from 'gatsby-plugin-image';
import { SecondaryButton } from '../../components/buttons';
import { ApplicationSection } from '../../components/contact';
import { graphql } from 'gatsby';
import Seo from '../../components/seo';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

function Karriere({ data, location }) {
    const { t } = useTranslation();
    const { language } = useI18next();
    const allJobs = data.allMarkdownRemark.edges.filter(
        job =>
            job.node.frontmatter.lang && job.node.frontmatter.lang === language
    );

    return (
        <Layout
            location={location}
            headerContent={
                <PageHeaderC
                    title={<Trans i18nKey="title">Karriere</Trans>}
                    subtitle={
                        <Trans i18nKey="subtitle">
                            Werde Teil des condignum-Teams und trage dazu bei,
                            die digitale Welt ein großes Stück sicherer zu
                            machen.
                        </Trans>
                    }
                    size="big"
                />
            }
            bgImage={bgImageDefault.default}
        >
            <Seo title={t('title', 'Karriere')} />
            <PageSection size="big" className="lg:py-4">
                <Col2 className="mx-auto max-w-md lg:max-w-full">
                    <PageText className="mb-0">
                        <Trans i18nKey="p1">
                            Condignum ist ein österreichisches
                            Cyber-Security-Unternehmen mit dem Ansporn, die
                            digitale Welt ein großes Stück sicherer zu machen.
                            Wir digitalisieren und automatisieren
                            Informationssicherheit und liefern Organisationen
                            jeder Größe die notwendige Hilfestellung, um aus
                            eigener Kraft sicherer zu werden. Im Fokus steht
                            unsere condignum Plattform und darauf aufbauende
                            Services.
                        </Trans>
                    </PageText>
                    <StaticImage
                        src="../../images/headers/jobs.png"
                        alt="Job"
                        objectFit="cover"
                        placeholder="none"
                        width={500}
                    />
                </Col2>
            </PageSection>

            <PageSection size="big" isDark>
                <div className="max-w-xs mx-auto sm:max-w-full">
                    <PageTitle className="mb-4">
                        <Trans i18nKey="open">Offene Stellen</Trans>
                    </PageTitle>
                    <ul className="grid gap-4 sm:grid-cols-2">
                        {allJobs.map(job => {
                            const {
                                slug,
                                title,
                                type,
                                position,
                            } = job.node.frontmatter;
                            return (
                                <BoxLight
                                    as="li"
                                    key={slug}
                                    className="p-4 flex flex-col justify-between"
                                >
                                    <div className="ml-2 max-w-[80%]">
                                        <PageText
                                            type="smallCaps"
                                            inline
                                            className="mb-0"
                                        >
                                            {type} | {t('gender', 'm/w/d')}
                                            {position && (
                                                <span>{' | ' + position}</span>
                                            )}
                                        </PageText>
                                        <PageSubtitle
                                            hasMargin={false}
                                            className="leading-6"
                                        >
                                            {title}
                                        </PageSubtitle>
                                    </div>
                                    <SecondaryButton
                                        className="mt-4"
                                        to={`/jobs/${slug}/`}
                                    >
                                        <Trans i18nKey="more">
                                            Mehr erfahren
                                        </Trans>
                                    </SecondaryButton>
                                </BoxLight>
                            );
                        })}
                    </ul>
                </div>
            </PageSection>

            <ApplicationSection />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["jobs"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        type
                        position
                        lang
                    }
                }
            }
        }
    }
`;

export default Karriere;
